import React, { useState } from 'react';
import '../assets/styles/Header.css';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    document.body.classList.toggle('menu-active');
  };

  return (
    <header className="header">
      <nav className="nav">
        <a href="/" className="nav__logo">
          <img src={logo} className="nav__logo" alt="DUELTEX NETWORK" />
        </a>
        {!showMenu && (
          <button className="nav__toggle" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        )}
        {showMenu && (
          <button className="nav__toggle" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        <div className={`nav__menu-container ${showMenu ? 'active' : ''}`} aria-label="navigation" >
          <ul className="nav__menu">
            <li className="nav__menu-item">
              <Link to="/" onClick={toggleMenu}>
                Home
              </Link>
            </li>
            <li className="nav__menu-item">
              <Link to="/about" onClick={toggleMenu}>
                About
              </Link>
            </li>
            <li className="nav__menu-item">
              <Link to="/services" onClick={toggleMenu}>
                Services
              </Link>
            </li>
            <li className="nav__menu-item">
              <Link to="/team" onClick={toggleMenu}>
                Our Team
              </Link>
            </li>
            <li className="nav__menu-item">
              <Link to="/contact-us" onClick={toggleMenu}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;