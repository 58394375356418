import React, { useEffect, useRef } from 'react';
import '../assets/styles/Main.css';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import vision_mission from '../assets/images/vision_mission.png';

function Main() {
  const mainRef = useRef(null);

  useEffect(() => {
    const mainElement = mainRef.current;

    const handleTouchStart = (event) => {
      if (event.target.closest('.carousel')) {
        mainElement.classList.add('dragging');
      }
    };

    const handleTouchMove = (event) => {
      if (mainElement.classList.contains('dragging')) {
        event.preventDefault();
        const touch = event.targetTouches[0];
        mainElement.scrollTop -= touch.pageY - touch.clientY;
      }
    };

    const handleTouchEnd = () => {
      mainElement.classList.remove('dragging');
    };

    mainElement.addEventListener('touchstart', handleTouchStart);
    mainElement.addEventListener('touchmove', handleTouchMove);
    mainElement.addEventListener('touchend', handleTouchEnd);

    return () => {
      mainElement.removeEventListener('touchstart', handleTouchStart);
      mainElement.removeEventListener('touchmove', handleTouchMove);
      mainElement.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <main className="main-container" ref={mainRef}>
      <section className="intro-section">
        <Carousel
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          transitionTime={500}
        >
          <div className="slide-item">
            <div className="slide-content">
              <h1 className="main-title">Welcome to Dueltex Network Ltd</h1>
              <p className="main-subtitle">Your trusted IT partner for comprehensive communication and data management solutions</p>
              <Link to="/about">
                <button className="main-button">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="slide-item">
            <div className="slide-content">
              <h1 className="main-title">Discover Our Services</h1>
              <p className="main-subtitle">IT/Telecoms Infrastructure, IT management, security surveillance systems, and more..</p>
              <Link to="/services">
                <button className="main-button">Explore Services</button>
              </Link>
            </div>
          </div>
        </Carousel>
      </section>

      {/* Rest of the code */}
      <section className="services-section">
        <h2 className="section-title">Our Services</h2>
        <div className="services-container">
          <div className="service-card">
            <i className="fas fa-laptop service-icon"></i>
            <h3 className="service-title">IT/Telcoms Infrastructure</h3>
            {/* <p className="service-desc">Small and large office networking solutions to optimize your operations.</p> */}
          </div>
          <div className="service-card">
            <i className="fas fa-phone-alt service-icon"></i>
            <h3 className="service-title">Labour Outsourcing</h3>
            {/* <p className="service-desc">Effective communication solutions to keep your team connected.</p> */}
          </div>
          <div className="service-card">
            <i className="fas fa-satellite-dish service-icon"></i>
            <h3 className="service-title">Security Surveillance Systems</h3>
            {/* <p className="service-desc">Reliable and secure communications solutions, even in remote locations.</p> */}
          </div>
          <div className="service-card">
            <i className="fas fa-video service-icon"></i>
            <h3 className="service-title">IT Management</h3>
            {/* <p className="service-desc">Television receive-only systems for reliable and high-quality satellite reception.</p> */}
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="section-inner">
          <h2 className="section-title">Our Vision & Mission</h2>
          <p className="about-desc">
            <strong>Vision:</strong> To be the first choice in the “realm of IT” by providing innovative and efficient solutions to exceed expectations.
            <br /><br />
            <strong>Mission:</strong> Deliver the world's best Information and communications resolutions that solutions that eennabllebubsuinsesinsees stosoeustrivtoal outrival
          </p>
          <img src={vision_mission} className="service_list" alt="DUELTEX MISSION/VISSION" />
        </div>
      </section>

    </main>
  );
}

export default Main;