import React from 'react';
import '../assets/styles/Teams.css';

const Teams = () => {
  const teamMembers = [
    {
      name: 'Anieno D. Inyang (Dixon)',
      position: 'Cisco Certified Network Associate And Head of Dueltex Network Ltd',
      bio: 'His experience spans through a wide range of information systems technology, Cisco Certified. He Studied Electronics and Telecommunications Engineering in the Petroleum Training Institute(PTI), Effurun, and later The University of South Africa. He has over eight years of work experience in oil and gas sector serving as Telecoms /IT Engineer and PC end user Support Analyst, supporting major projects. And has vast experience in WAN & LAN design and implementation, computer systems maintenance and end user support. He has wide telecoms experience covering both onshore and offshore environment. He is currently the head of Dueltex Network Ltd business Development.',
      image: 'https://via.placeholder.com/300x300'
    },
    {
      name: 'Darlington Okechukwu',
      position: 'Field Technical Lead',
      bio: 'Darlington Okechukwu is the Field Technical Lead of Dueltex network Ltd. He holds a B.Eng degree in Electrical and Electronic Engineering from Enugu State University of science Technology, Enugu. Darlington is CISCO certified and a highly skilled IT Network Engineer with over 7 years of experience in installation and maintenance of Voice and data communication facilities. He has a strong background efficient in both onshore and offshore environment.',
      image: 'https://via.placeholder.com/300x300'
    },
    {
      name: 'Femi Fifuneyin',
      position: 'Project Supervisor TVRO and Security systems design and implementation',
      bio: 'Femi Fifuneyin is a Cisco Certified Network associate with Broad knowledge of networking, Telecoms Systems and Desktop support. He studied Telecoms Engineering in the Federal Polytechnic Edo Ekiti. He has over ten years of work experience in the oil and gas sector. Working as Telecoms Analyst, E-security solutions , IT admin, Network and Project Information management Coordinator across businesses, infrastructure and operations units, providing support to project mgt/Operations team. Femi has led teams Implementing TVRO, security CCTV systems in a major oils and gas Company. Femi is currently project supervisor, in charge of TVRO and Security systems design and implementation of Dueltex network ltd.',
      image: 'https://via.placeholder.com/300x300'
    },
    {
      name: 'Imekan Thompson Enang',
      position: 'Field Support Engineer',
      bio: 'Mr Imekan Enang is a Computer/IT systems support expert. He studied Computer Science in the University of Uyo. His experience and training span from Satellite equipment installation, WAN/LAN setup and management to Structural office cabling and TVRO systems. Thompson is an all rounder as he is also an asset in logistics and coordination. He is currently serves as a field support staff.',
      image: 'https://via.placeholder.com/300x300'
    },
    {
      name: 'Aniekan Etim',
      position: 'Field Support Engineer',
      bio: 'Mr Aniekan Etim is Computer/IT Systems Support expert. He is a graduate of Computer Engineering from the University of Uyo. He is experienced in Satellite equipment installation and maintenance. He serves as Field Support Engineer.',
      image: 'https://via.placeholder.com/300x300'
    },
    {
      name: 'Ikpong James Anana',
      position: 'Field Support Engineer',
      bio: 'Miss Anana graduated as Telecommunications Engineer from the Federal University of Technology, Owerri. Her training includes; WAN/ LAN implementation, satellite equipment installation and commissioning. Miss Anana currently serves as field support Engineer .',
      image: 'https://via.placeholder.com/300x300'
    }
  ];

  return (
    <section className="main-section" id="teams">
      <div className="container">
        <div className="section-heading">
          <h2>Meet Our Team</h2>
          <div className="underline"></div>
        </div>
        <div className="team-grid">
          {teamMembers.map((teamMember, index) => (
            <div key={index} className="team-member">
              <div className="team-member-image">
                <img src={teamMember.image} alt={teamMember.name} />
              </div>
              <div className="team-member-details">
                <h3>{teamMember.name}</h3>
                <h4>{teamMember.position}</h4>
                <p>{teamMember.bio}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Teams;
