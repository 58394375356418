import React from 'react';
import '../assets/styles/AboutUs.css';

const AboutUs = () => {
  return (
    <section className="about-section">
      <h1 className="about-title">About Us</h1>
      <div className="about-content">
        <div className="about-info">
          <img src="https://via.placeholder.com/300x300" alt="Quality Assurance" className="about-info-image" />
          <div>
            <h2 className="about-info-title">Our Undertaking on Quality</h2>
            <p className="about-info-desc">
              Quality Assurance and Quality Control are our hallmark. We ensure
              quality through active involvement of all our management and staff in
              the process of planning, organizing, executing and fulfilling all
              stakeholders requirements and continuous investment in human capital
              development and technology update.
            </p>
          </div>
        </div>
        <div className="about-info">
          <img src="https://via.placeholder.com/300x300" alt="Vision" className="about-info-image" />
          <div>
            <h2 className="about-info-title">VISION</h2>
            <p className="about-info-desc">
              To be the first choice in the "realm of IT" by providing innovative
              and efficient solutions to exceed expectations.
            </p>
          </div>
        </div>
        <div className="about-info">
          <img src="https://via.placeholder.com/300x300" alt="Mission" className="about-info-image" />
          <div>
            <h2 className="about-info-title">MISSION</h2>
            <p className="about-info-desc">
              Deliver the world's best Information and communications resolutions that solutions that eennabllebubsuinsesinsees stosoeustrivtoal outrival
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
