import { useState } from 'react';
import emailjs from 'emailjs-com';
import '../assets/styles/Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [errors, setErrors] = useState({});

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const validateForm = () => {
    const errors = {};

    if (name.trim() === '') {
      errors.name = 'Name is required';
    }

    if (email.trim() === '') {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }

    if (message.trim() === '') {
      errors.message = 'Message is required';
    }

    return errors;
  };

  const sendEmail = (templateParams) => {
    const emailNotAllowed = email.includes('.test') || email.includes('example.com') || email.includes('example');

    if (emailNotAllowed) {
      setErrors({ email: 'Failed to send email. Email is not allowed.' });
      return;
    }

    emailjs
      .send('service_suuq3m9', 'template_fa2i7yj', templateParams, 'S2hVyay9S9PNRDIb9')
      .then((response) => {
        console.log('Email sent!', response.status, response.text);
        setSent(true);
        setErrors({});
        setTimeout(() => setSent(false), 10000);
      })
      // .catch((error) => {
      //   console.error('Error sending email:', error);
      //   setErrors({ email: 'Failed to send email. Please try again later.' });
      // });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      const templateParams = {
        name,
        email,
        message
      };
      sendEmail(templateParams);
    }
  };

  return (
    <section className="contact" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h2 className="card-title">Contact Us</h2>
                <hr />
                <div className="row">
                  <div className="col-md-6" style={{ textAlign: '-webkit-center' }}>
                    <p className="card-text">
                      Dueltex Network Ltd currently has two operational offices
                      located in Warri Delta State and Port Harcourt, Rivers
                      State, Nigeria.
                    </p>
                    <h5>Headquarters:</h5>
                    <p className="card-text">
                      No. 8 Weco Road, off Water Resources Junction,
                      Warri-Sapele Road, Warri
                    </p>
                    <p className="card-text">
                      <strong>Phone:</strong> +2348057299452
                    </p>

                    <h5>Branch Office:</h5>
                    <p className="card-text">
                      No. 21 PH/Aba Expressway, opposite R.T Briscoe, Port
                      Harcourt.
                    </p>
                    <p className="card-text">
                      <strong>Phone:</strong> +2348057299452
                    </p>
                  </div>
                  <div className="col-md-6" style={{ textAlign: '-webkit-center' }}>
                    {/* Map component */}
                    <div style={{ height: '250px', width: '100%' }}>
                      <iframe
                        title="Dueltex Network Headquarters"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2536.293058973699!2d5.760832215582291!3d5.515932013694631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104db8269fbf18d1%3A0x9f9a0d25b8254d10!2sDueltex%20Network%20Ltd!5e0!3m2!1sen!2sng!4v1658291311873!5m2!1sen!2sng"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className={`form-control ${errors.name && 'is-invalid'}`}
                  id="name"
                  placeholder="Name"
                  required
                  value={name}
                  onChange={handleNameChange}
                />
                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className={`form-control ${errors.email && 'is-invalid'}`}
                  id="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={handleEmailChange}
                />
                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  className={`form-control ${errors.message && 'is-invalid'}`}
                  id="message"
                  rows="5"
                  placeholder="Message"
                  required
                  value={message}
                  onChange={handleMessageChange}
                ></textarea>
                {errors.message && <div className="invalid-feedback">{errors.message}</div>}
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              {sent && (
                <div className="alert alert-success mt-3" role="alert">
                  Your message has been sent.
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
