import React from 'react';
import '../assets/styles/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" integrity="sha512-Lm0lQ+fJhG9fR7tiu/jUb+5L5ha5tl5RH5I7q1Z5xEaa25SUn1NzabUdzXgU6x4Ny/4YKy6eK1VZIw81ad43zg==" crossorigin="anonymous" referrerpolicy="no-referrer" />


function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-item">
        <h4>About Us</h4>
        <p>Dueltex Network Limited is a multidiscipline information Technology Company and Service Contractor, set up to provide Communication/data management , Security Systems solutions to both corporate and government agencies. We provide wide range of IT services ranging from provision of Satellite Communications, installation/support for terrestrial and marine communications systems, TVRO systems to small and large office IT networking solutions and telephone intercom system. We also provide supply/procurement and installations services to our clients.</p>
      </div>
      <div className="footer-item">
        <h4>Quick Links</h4>
        <ul>
          <li><a href="/about">About</a></li>
          <li><a href="/services">Solution & Services</a></li>
          <li><a href="/team">Our Team</a></li>
          <li><a href="/contact-us">Contact Us</a></li>
        </ul>
      </div>
      <div className="footer-item">
        <h4>Contact Us</h4>
        <p><strong>Dueltex Network Ltd </strong>currently has two operational offices located in Warri Delta State and Port Harcourt, Rivers State, Nigeria.</p>
        <p>No. 8 Weco Road, off water resources Junction, Warri-Sapele Road, Warri<br /> <br />
          No. 21 PH/Aba Expressway, opp R.T Briscoe, Port Harcourt.<br /> <br />

          Phone: +2348057299452<br />
          Email: info@dueltex.com</p>
      </div>
      <div className="footer-item">
        <h4>Connect with Us</h4>
        <ul className="social-icons">
          <li><a href="#"><FontAwesomeIcon icon={faFacebookF} /></a></li>
          <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
          <li><a href="#"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
          <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
