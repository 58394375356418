import React from 'react';
import '../assets/styles/Services.css';
import service1 from '../assets/images/It-Management-service.png';
import service2 from '../assets/images/service-two.png';

const Services = () => {
  return (
    <div className="services-container">
      <h1>Our Services</h1>
      <div className="services-grid">
        <div className="service-container">
          <div className="service-box">
            <h2>IT Infrastructure</h2>
            <div className='it_list'>
              <li>Mobile/Marine Digital satellite television solutions (TV at Sea)</li>
              <li>Mobile/marine Internet (Broadband) & Voice Solutions</li>
              <li>Hardware and Networking</li>
              <li>All radios/Satellite Telecommunication repairs, supply and installations</li>
              <li>Telephone intercom systems</li>
              <li>Structured Office Cabling and Solutions</li>
              <li>Wireless Communications Solutions</li>
              <li>Procurement/Supply of IT equipment and installations</li>
              <li>Labour Providers</li>
            </div>
            <img src={service1} className="service_list" alt="DUELTEX SERVICE" />
          </div>
        </div>

        <div className="service-container">
          <div className="service-box">
            <h2>Manage Service Outsourcing</h2>
            <strong>Cabling Solutions</strong>
            <p>
              A structured cabling system provides the platform upon which an overall information system strategy is built.
              Dueltex network ltd ICT experienced and dedicated team provides an 'end to end' service from design to implementation.
              We design and build cabling infrastructure systems that support multiple voice, data, video and multimedia systems.
              We will help you evaluate, design and install the optimum integrated system for your company.
              We provide infrastructure cabling of the highest quality, to ensure that your networks run at full wire speed
              and maintains the highest possible availability.
            </p>
            <p>
              <strong>Our Structured Cabling services include: </strong>
            </p>
            <p>
              <strong>Optical Fibre Cabling:</strong> We also design and build fibre drop cables as per various ISP’s requirements and standards.
            </p>
          </div>
        </div>

        <div className="service-container">
          <div className="service-box">
            <h2>Hardware and Networking</h2>
            <strong>Storage &amp; Backup Solution:</strong>
            <p>
              With explosive growth in data, many organizations are struggling to manage, protect,
              mitigate risk, and maintain rapidly growing information assets while ensuring compliance
              with industry regulations.
              Alpha Data offers a wide range of storage solutions including SAN, NAS,
              Enterprise Backup, Archiving and Disaster Recovery Solutions. So regardless of your organizations' size,
              we have a storage solution to ensure your information assets are secure.
            </p>
            <br />
            <br />
            <strong>Telecom &amp; Unified communication</strong>
            <p>
              Dueltex Network Ltd implements and maintains converged voice and data communications.
              Dueltex Network Ltd is a Business Partner for leading principles across the world; Inmarsat,
              GlobalComSatcom, GilatSatcom and Livewire Connections. At Dueltex, we recognize that all businesses are different
              and require different functionality from their telephony system. Dueltex’s proposition is about
              customizing a complete communications infrastructure that best suits the client's business
              needs now and in the future. IP Telephony or VoIP (voice over IP) is the de-facto standard for
              carrying voice on the communications network. IP Telephony delivers many applications designed to improve
              the way you work, improve your business processes and making communicating easy.
              The most exciting applications that IP Telephony brings fall under the banner of Unified Communications.
            </p>
          </div>
        </div>

        <div className="service-container">
          <div className="service-box">
            <h2>Security Surveillance Systems</h2>
            <p>
              CCTV Installation in Nigeria requires security analysis depending on past incidents and critical areas at the site &amp; within the company. Depending on the report a comprehensive CCTV security installation
            </p>
            <img src={service2} className="service_list" alt="DUELTEX SERVICE" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
